<template>
  <div style="display: flex;flex-wrap: wrap;">
  <span class="tool-img"><img src="../../../../assets/gis/tool/leftheader.png"/></span>
    <div class="tool-bar">
      
      <div v-show="toolShow" class="tool-list">
        <li v-for="(item, index) in toolList" :key="index">
          <el-tooltip class="tooltipGis"  :content="item.tip" placement="top">
            <div
              :class="activeToolItem == item.value ? 'active' : ''"
              @click="startDraw(item.value, item.layer, item.name, item.layerName)"
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg>
              <span>{{ item.name }}</span>
            </div>
          </el-tooltip>
        </li>
        <el-popover
          v-for="(item, index) in lineEqList"
          :key="'eq' + index"
          placement="bottom"
          trigger="click"
          popper-class="tool-pop"
        >
          <div class="tool-pop-item">
            <li
              v-for="(itemsub, indexsub) in item.children"
              :key="'eqsub' + indexsub"
              :class="activeToolItem == itemsub.value ? 'active' : ''"
              @click="startDrawEquipment(itemsub, item.layerName)"
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="itemsub.img"></use>
              </svg>
              <span style="margin-right: 10px">{{ itemsub.name }}</span>
            </li>
          </div>
          <li v-if="item.type == 'multiple'" slot="reference">
            <div
              :class="
                activeToolItem == item.children[0].value || activeToolItem == item.children[1].value
                  ? 'active'
                  : ''
              "
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg>
              <span>{{ item.name }}</span>
              <span style="float: right; margin-top: -10px; margin-right: -7px">
                <img src="../../image/tool-subscript.png" />
              </span>
            </div>
          </li>
        </el-popover>
      </div>
      <li>
        <div :class="['isShow', 'back-style', { unfold: !toolShow }]" @click="toolShow = !toolShow">
          <img src="../../../../assets/gis/tool/toll-left.png" />
        </div>
      </li>
    </div>
    <span class="tool-img" style="margin-left:10px"><img src="../../../../assets/gis/tool/leftheader.png"/></span>
    <div class="tool-bar">
      <!-- <span class="tool-name">操作工具</span> -->
      <div v-show="operationShow" class="tool-list">
        <li v-for="(item, index) in optionList" :key="index">
          <el-tooltip class="tooltipGis" :content="item.tip" placement="top">
            <div
              :aria-disabled="true"
              :class="activeToolItem == item.value ? 'active' : ''"
              @click="startDraw(item.value, 'no', item.name)"
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg>
              <span>{{ item.name }}</span>
            </div>
          </el-tooltip>
        </li>
        <li>
          <el-tooltip class="tooltipGis" placement="top">
            <div slot="content"><span v-html="adsorptionArr.tip"></span></div>
            <div
              ref="adsorption"
              :aria-disabled="true"
              :class="activeOptionItem == adsorptionArr.value ? 'active' : ''"
              @click="startOption(adsorptionArr)"
            >
              <svg
                v-if="activeOptionItem == adsorptionArr.value"
                class="icon svg-icon"
                aria-hidden="true"
                :style="adsorptionArr.size || {}"
              >
                <use :xlink:href="adsorptionArr.img"></use>
              </svg>
              <svg
                v-else
                class="icon svg-icon"
                aria-hidden="true"
                :style="adsorptionArr.size || {}"
              >
                <use :xlink:href="adsorptionArr.img1"></use>
              </svg>
              <span>{{ adsorptionArr.name }}</span>
            </div>
          </el-tooltip>
        </li>
      </div>
      <li>
        <div
          :class="['isShow', 'back-style', { unfold: !operationShow }]"
          @click="operationShow = !operationShow"
        >
          <img src="../../../../assets/gis/tool/toll-left.png" />
        </div>
      </li>
    </div>
    <span class="tool-img" style="margin-left:10px"><img src="../../../../assets/gis/tool/leftheader.png"/></span>
    <div class="tool-bar">
      <!-- <span class="tool-name">地图工具</span> -->
      <div v-show="mapToolShow" class="tool-list">
        <el-popover
          v-model="pointVisible"
          placement="bottom"
          trigger="hover"
          popper-class="tool-pop"
        >
          <div class="sketchImg">
            <span v-for="item in sketchImg" :key="item.value" class="span" @click="selectImg(item)">
              <svg class="icon svg-icon img" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg>
            </span>
          </div>
          <li slot="reference">
            <div :class="toolActive == mapToolList[0].value ? 'active' : ''">
              <svg class="icon svg-icon img" aria-hidden="true">
                <use :xlink:href="mapToolList[0].img"></use>
              </svg>
              <span>{{ mapToolList[0].name }}</span>
              <span style="float: right; margin-top: -10px; margin-right: -7px;">
                <img src="../../image/tool-subscript.png" />
              </span>
            </div>
          </li>
        </el-popover>

        <el-popover
          v-model="lineVisible"
          placement="bottom"
          trigger="hover"
          popper-class="tool-pop"
        >
          <div class="tool-pop-item">
            <div class="selectColor">
              <span>颜色</span>
              <el-color-picker v-model="sketchColor" @change="selectColor"></el-color-picker>
            </div>
            <li
              v-for="(itemsub, indexsub) in mapToolList[1].children"
              :key="indexsub"
              @click="setDrawSketch(itemsub)"
            >
              <!-- <img :src="itemsub.img" /> -->
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="itemsub.img"></use>
              </svg>

              <span>{{ itemsub.name }}</span>
            </li>
          </div>
          <li slot="reference">
            <div
              :class="
                toolActive == 'polyline' ||
                toolActive == 'polygon' ||
                toolActive == 'circle' ||
                toolActive == 'rectangle'
                  ? 'active'
                  : ''
              "
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="mapToolList[1].img"></use>
              </svg>
              <span>{{ mapToolList[1].name }}</span>
              <span style="float: right; margin-top: -10px; margin-right: -7px">
                <img src="../../image/tool-subscript.png" />
              </span>
            </div>
          </li>
        </el-popover>

        <li>
          <el-tooltip class="tooltipGis" :content="mapToolList[2].tip" placement="top">
            <div
              :class="toolActive == mapToolList[2].value ? 'active' : ''"
              @click="draw(mapToolList[2].value)"
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="mapToolList[2].img"></use>
              </svg>
              <span>{{ mapToolList[2].name }}</span>
            </div>
          </el-tooltip>
        </li>
       
        <li>
          <el-tooltip class="tooltipGis" :content="mapToolList[3].tip" placement="top">
            <div @click="clearTopTool()">
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="mapToolList[3].img"></use>
              </svg>
              <span>{{ mapToolList[3].name }}</span>
            </div>
          </el-tooltip>
        </li>
      </div>
      <li>
        <div
          :class="['isShow', 'back-style', { unfold: !mapToolShow }]"
          @click="mapToolShow = !mapToolShow"
        >
          <img src="../../../../assets/gis/tool/toll-left.png" />
        </div>
      </li>
      </div>
       <span class="tool-img" style="margin-left:10px"><img src="../../../../assets/gis/tool/leftheader.png"/></span>
    <div class="tool-bar">
      <el-popover
          v-for="(item, index) in lanalysisList"
          :key="'eq' + index"
          placement="bottom"
          trigger="click"
          popper-class="tool-pop"
        >
          <div class="tool-pop-item">
            <li
              v-for="(itemsub, indexsub) in item.children"
              :key="'eqsub' + indexsub"
              :class="activeToolItem == itemsub.value ? 'active' : ''"
              @click="examine(itemsub, item.layerName)"
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="itemsub.img"></use>
              </svg>
              <span style="margin-right: 10px">{{ itemsub.name }}</span>
            </li>
          </div>
          <li v-if="item.type == 'multiple'" slot="reference">
            <div
              :class="
                activeToolItem == item.children[0].value || activeToolItem == item.children[1].value
                  ? 'active'
                  : ''
              "
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg>
              <span>{{ item.name }}</span>
              <span style="float: right; margin-top: -10px; margin-right: -7px">
                <img src="../../image/tool-subscript.png" />
              </span>
            </div>
          </li>
        </el-popover>
       <li v-for="item,index in fxList" :key="index">
          <el-tooltip class="tooltipGis"  :content="item.tip" placement="top">
            <div
              :class="toolActive == item.value ? 'active' : ''"
              @click="draw(item.value)"
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg>
              <span>{{ item.name }}</span>
            </div>
          </el-tooltip>
        </li>
        
        
        <li>
        <div
          :class="['isShow', 'back-style', { unfold: !mapToolShow }]"
          @click="mapToolShow = !mapToolShow">
          <img src="../../../../assets/gis/tool/toll-left.png" />
        </div>
      </li>
      </div>
    <dialog-node v-if="activeToolItem == 'node'" ref="dialogNode" />
    <dialog-monitor v-else-if="activeToolItem == 'monitoring'" ref="dialogNode" />
    <dialog-valve v-else-if="activeToolItem == 'valve'" ref="dialogNode" />
    <dialog-userSta v-else-if="activeToolItem == 'userSta'" ref="dialogNode" />
    <dialog-station v-else-if="activeToolItem == 'station'" ref="dialogNode" />
    <dialog-line v-else-if="activeToolItem == 'line'" ref="dialogNode" />
    <dialog-warn v-else-if="activeToolItem == 'warn'" ref="dialogNode" />
    <dialog-camera v-else-if="activeToolItem == 'camera'" ref="dialogNode" />
    <div class="bubbleBox">
      <div ref="changePoint" class="Bubble">
        <div class="markbox">
          <div class="markTitle">
            <p>
              <span>提示</span>
            </p>
            <i class="el-icon-error" @click="closeWindow()"></i>
          </div>
          <div class="mark-con">
            <p>是否要转换为三通？</p>
            <div class="btn">
              <span>
                <el-button
                  type="primary"
                  style="margin-left:10px"
                  @click="selectDragButton(3, constantDict.santong.dictCode)"
                  >转为{{ constantDict.santong.name }}</el-button
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dialogNode from '../../dialog/node'
import dialogMonitor from '../../dialog/monitoring'
import dialogValve from '../../dialog/valve'
import dialogUserSta from '../../dialog/userSta'
import dialogStation from '../../dialog/station'
import dialogLine from '../../dialog/line'
import dialogWarn from '../../dialog/warn'
import dialogCamera from '../../dialog/camera'
import { queryFeatrue } from '../../apis/geo'
import { findIsPressureFree, pipeLink } from '../../apis/tool.js'
import { constantDict, commonLayer, serverMap } from '../../utils/dict.js'
import { centerPointGetFourPoint, filterLayer, formatLocation } from '../../utils/geoTool.js'
//import { timeStamp } from 'console'
export default {
  name: 'TopTool',
  components: {
    dialogNode,
    dialogMonitor,
    dialogValve,
    dialogUserSta,
    dialogStation,
    dialogLine,
    dialogWarn,
    dialogCamera
  },
  data() {
    return {
      infoWindow: null,
      mouseObj: null,
      mouseIndex: 0,
      windowState: false,
      mousePre: false,
      constantDict: constantDict,
      pointVisible: false,
      lineVisible: false,
      sketchColor: '#3e8bff',
      activeToolItem: '',
      activeOptionItem: '',
      lineArr: [],
      toolShow: true,
      operationShow: true,
      mapToolShow: true,
      lineType: 'start',
      openLoad: false,
      toolList: [
        {
          name: '节点',
          tip:
            '在管线上增加普通节点，节点连接的管线是连通；绘制时，默认吸附功能；点击节点，可编辑节点种类',
          img: '#icon-a-jiedian1',
          value: 'node',
          layer: constantDict.jiedian.dictCode,
          layerName: commonLayer.baseLine
        },
        {
          name: '管线',
          tip: '绘制管线；绘制时，可手工打开或关闭吸附功能',
          img: '#icon-xian',
          value: 'line',
          layerName: commonLayer.basePoint
        },
        {
          name: '用户站',
          tip: '绘制用户站，调压箱、调压柜等',
          img: '#icon-tiaoyazhan',
          value: 'userSta',
          layer: constantDict.yonghuzhan.dictCode,
          layerName: commonLayer.basePoint
        },
        {
          name: '供气站',
          tip: '绘制供气站，门站、储配站等',
          img: '#icon-menzhan',
          value: 'station',
          layer: constantDict.gongqizhan.dictCode,
          layerName: commonLayer.basePoint
        },
        {
          name: '阀门',
          tip: '绘制阀门；在节点或直接在管线上添加阀门；绘制时，默认吸附功能',
          img: '#icon-famen1',
          value: 'valve',
          layer: serverMap['1002'][0].dictCode,
          layerName: commonLayer.pressure
        }
        ,
        {
          name: '监测点',
          tip: '绘制监测点；在管线上添加监测点；绘制时，默认吸附功能',
          img: '#icon-a-jiedian1-copy',
          value: 'monitoring',
          layer: constantDict.jiancedian.dictCode,
          layerName: commonLayer.baseLine
        }
      ],
      lineEqList: [
        {
          name: '安全设备',
          img: '#icon-xieloubaojing',
          value: 'warn',
          type: 'multiple',
          children: [
            {
              name: '泄露报警器',
              img: '#icon-xieloubaojing',
              value: 'warn',
              layer: constantDict.xieloubaojing.dictCode
            },
            {
              name: '摄像头',
              img: '#icon-shexiangtou',
              value: 'camera',
              layer: constantDict.shexiangtou.dictCode
            }
          ]
        }
      ],
      optionList: [
        {
          name: '拖拽',
          tip: '左键选中管线节点，进行拖拽',
          img: '#icon-tuozhuai',
          value: 'drag'
        },
        {
          name: '打断',
          tip: '左键点击管线节点或管线任意位置，实现打断；打断位置为重合节点，管线为不连通',
          img: '#icon-daduan',
          value: 'interrupt'
        }
      ],
      adsorptionArr: {
        name: '吸附',
        tip:
          '打开或关闭吸附；在绘制节点、监测点、阀门时，默认打开，不允许关闭<br/>地图级别小于11级不支持吸附',
        img: '#icon-switch_open1',
        img1: '#icon-switch_open',
        size: { height: '15px', width: '0.3rem' },
        value: 'adsorption'
      },
      mapToolList: [
        { name: '标记', img: '#icon-biaoji', value: 'marker', type: 'draw' },
        {
          name: '草图',
          img: '#icon-caotu',
          value: 'sketch',
          type: 'multiple',
          children: [
            {
              name: '线',
              img: '#icon-caotu',
              value: 'polyline',
              type: 'draw'
            },
            {
              name: '多边形',
              img: '#icon-duobianxing',
              value: 'polygon',
              type: 'draw'
            },
            {
              name: '圆形',
              img: '#icon-yuan',
              value: 'circle',
              type: 'draw'
            },
            {
              name: '矩形',
              img: '#icon-fangxing',
              value: 'rectangle',
              type: 'draw'
            }
          ]
        },
        { name: '测距', img: '#icon-ceju', value: 'rule', tip: '测量地图绘制折线距离' },
       
        { name: '清除', img: '#icon-qingkongtuxing', value: '', tip: '恢复工具条默认状态' }
      ],
      fxList:[
        {
          name: '显示设置',
          img: '#icon-duobianxing',
          value: 'setting',
          tip: '显示设置'
        },
         {
          name: '框选查询',
          img: '#icon-duobianxing',
          value: 'polygonQuery',
          tip: '查询框内的管线、场站、阀门及仪表设备'
        },
        {
          name: '更换图层',
          img: '#icon-duobianxing',
          value: 'polygonQueryTC',
          tip: '更换图层'
        },
      ],
      lanalysisList:[{
          name: '网管检查',
          img: '#icon-xieloubaojing',
          value: 'examineLine',
          type: 'multiple',
          children: [
            {
              name: '连通性分析',
              img: '#icon-xieloubaojing',
              value: 'examineLine',
              layer: constantDict.xieloubaojing.dictCode
            },
            {
              name: '未闭合节点',
              img: '#icon-shexiangtou',
              value: 'examinePoint',
              layer: constantDict.shexiangtou.dictCode
            }
          ]
        }],
      toolActive: 'drag',
      sketchActive: '',
      sketchImg: [
        { value: 'mark_b', img: '#icon-dingwei' },
        { value: 'ding', img: '#icon-biaojibeifen' },
        { value: 'hongqi', img: '#icon-biaoji-copy' },
        { value: 'miao', img: '#icon-biaojimiaozhun-copy' },
        { value: 'shigong', img: '#icon-shigong' },
        { value: 'shigong2', img: '#icon-shigong1' },
        { value: 'text', img: '#icon-biaoji1' },
        { value: 'weizhi', img: '#icon-position-mark' },
        { value: 'xing', img: '#icon-jiaxingshoucangtianchong' }
      ],
      selectPipe: []
    }
  },
  watch: {
    activeOptionItem(val) {
      this.$store.commit('gis/activeOptionChange', val)
    }
  },
  mounted() {},
  methods: {
    closeWindow() {
      let path = this.mouseObj.getPath()
      let i = path.length - 1
      path.splice(this.mouseIndex, 1)
      this.windowState = true
      this.mousePre = false
      setTimeout(() => {
        this.lineArr.splice(this.mouseIndex, 1)
      }, 300)
      this.mouseObj.setPath(path)
      if (this.infoWindow) {
        this.infoWindow.close()
      }
    },
    examine(obj,layerName){
      let code = obj.value;
      if(code=='examineLine'){
        this.$eventBus.$emit('analysisLayer', { name: commonLayer.groupLine })
      }else{
        this.$eventBus.$emit('analysisLayer', { name: commonLayer.groupPoint })
      }
    },
    selectDragButton() {
      this.closeWindow()
      setTimeout(() => {
        if (this.lineArr.length == 1) {
          this.lineArr[this.mouseIndex - 1].startNodeType = constantDict.santong.dictCode
        } else {
          this.lineArr[this.mouseIndex - 2].startNodeType = constantDict.santong.dictCode
          if (this.lineArr[this.mouseIndex - 3]) {
            this.lineArr[this.mouseIndex - 3].endNodeType = constantDict.santong.dictCode
          }
        }
      }, 300)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 清除所有toop上的操作
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-31
     ****************************************************************************************************
     */
    clearNoRemove() {
      this.selectPipe = []
      this.toolActive = ''
      this.$parent.isEdit = false
      this.$parent.isSelectNode = false
      this.$parent.drawType = ''
      this.activeToolItem = ''
      this.activeOptionItem = ''
      this.$parent.editTool.close(false)
      this.$eventBus.$emit('clearDrag')
      this.$parent.map.setStatus({
        dragEnable: true,
        zoomEnable: true
      })
      this.$parent.lineHighlightRemove()
      this.$parent.pointHighlightRemove()
      this.$parent.rectangleHighlightFRemove()
      this.$parent.circleHighlightRemove()
      this.$parent.clearQuery()
      if (this.$parent.$refs.markInfo.infoWindow) {
        this.$parent.$refs.markInfo.infoWindow.close()
      }
      if (this.$parent.$refs.queryInfo.dialogVisible) {
        this.$parent.$refs.queryInfo.colse()
      }
      this.$parent.queryLayer = ''
      this.$parent.queryType = ''
    },
    clearTopTool() {
      console.log(this.$parent.noclose)
      if (this.$parent.noclose) {
      } else {
        this.closeTool()
        if (this.$parent.$refs.markInfo.infoWindow) {
          this.$parent.$refs.markInfo.infoWindow.close()
        }
        if (this.$parent.$refs.queryInfo.dialogVisible) {
          this.$parent.$refs.queryInfo.colse()
        }
        if (this.$parent.$refs.stationInfo.dialogVisible) {
          this.$parent.$refs.stationInfo.colse()
        }
      }
      this.selectPipe = []
      this.toolActive = ''
      this.$parent.isEdit = false
      this.$parent.isSelectNode = false
      this.$parent.drawType = ''
      this.activeToolItem = ''
      this.activeOptionItem = ''
      this.$eventBus.$emit('clearDrag')
      this.$parent.map.setStatus({
        dragEnable: true,
        zoomEnable: true
      })
      this.$parent.lineHighlightRemove()
      this.$parent.pointHighlightRemove()
      this.$parent.rectangleHighlightFRemove()
      this.$parent.circleHighlightRemove()
      this.$parent.clearQuery()

      this.$parent.queryLayer = ''
      this.$parent.queryType = ''
    },
    /*
     ****************************************************************************************************
     * 功能描述： 绘制图标
     * 开发人： 孙志萍
     * 开发时间： 2022-5-23
     ****************************************************************************************************
     */
    selectImg(item) {
      let sketchType = this.$store.getters.sketchType
      if (sketchType.indexOf('point') == -1 && this.sketchActive != item.value) {
        this.$notify({
          title: '提示',
          message: `您当前未打开"标记"图例，会影响绘制结果可见性！`,
          position: 'bottom-right',
          type: 'warning',
          customClass: 'notify-warning'
        })
      }
      this.pointVisible = false
      this.mapToolList[0].img = item.img
      this.sketchActive = item.value
      this.draw('marker', item.value)
    },
    setDrawSketch(item) {
      this.lineVisible = false
      this.mapToolList[1].img = item.img

      this.draw(item.value)

      let value = item.value == 'polyline' ? 'line' : item.value
      let sketchType = this.$store.getters.sketchType
      let vals = ['polyline', 'polygon', 'circle', 'rectangle']
      if (sketchType.indexOf(value) == -1 && vals.indexOf(this.toolActive) != -1) {
        this.$notify({
          title: '提示',
          message: `您当前未打开"草图"图例，会影响绘制结果可见性！`,
          position: 'bottom-right',
          type: 'warning',
          customClass: 'notify-warning'
        })
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 绘制颜色
     * 开发人： 孙志萍
     * 开发时间： 2022-5-23
     ****************************************************************************************************
     */
    selectColor(val) {
      this.$parent.sketchColor = val
    },
    closeTool() {
      //this.$emit('closeDraw')
      this.$parent.drawState = false
      this.$parent.editTool.close(true)
      this.$parent.mouseTool.close(true)
      this.$parent.equipmentTool.close(true)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 工具栏
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-20
     ****************************************************************************************************
     */
    draw(value, img) {
      if(value=='setting'){
        this.$parent.settingsItem('dialogDisplay')
      }else{
        if (this.toolActive == value) {
          this.clearTopTool()
          this.toolActive = ''
        } else {
          this.clearTopTool()
          this.$parent.draw(value, img)
          this.toolActive = value
          this.$parent.isEdit = true
        }
      }
      
    },
    /*
     ****************************************************************************************************
     * 功能描述： 开始绘制管网设备
     * 开发人： 庞红川
     * 开发时间： 2022-5-25
     * @item.value 泄露报警器warn、摄像头camera等
     ****************************************************************************************************
     */
    startDrawEquipment(item, layerName) {
      let that = this
      if (that.activeToolItem == item.value) {
        that.clearTopTool()
        that.$parent.isEdit = false
        that.activeToolItem = ''
        that.$refs.adsorption.style.cursor = 'pointer'
        this.$parent.queryLayer = ''
        this.$parent.queryType = ''
      } else {
        that.clearTopTool()
        that.$parent.drawTipmouse = '单击，完成绘制'
        that.$parent.isEdit = true
        that.activeToolItem = item.value
        that.activeOptionItem = ''
        that.$refs.adsorption.style.cursor = 'not-allowed'

        this.$parent.queryLayer = layerName
        this.$parent.queryType = item.value

        let sketchType = this.$store.getters.legendSelect
        if (sketchType.indexOf(item.layer) == -1) {
          this.$notify({
            title: '提示',
            message: `您当前未打开"${item.name}"图例，会影响绘制结果可见性！`,
            position: 'bottom-right',
            type: 'warning',
            customClass: 'notify-warning'
          })
        }
        let image = null
        if (item.value == 'warn') {
          image = require('../../image/tool-warn.png')
        } else if (item.value == 'camera') {
          image = require('../../image/tool-camera.png')
        }
        that.$parent.equipmentTool.marker({
          icon: new AMap.Icon({
            image,
            imageOffset: new AMap.Pixel(0, 0)
          }),
          anchor: 'center',
          offset: new AMap.Pixel(0, 0)
        })
        that.$parent.equipmentTool.on('draw', function(event) {
          let obj = event.obj
          that.lnglat = obj.getPosition().lng + ' ' + obj.getPosition().lat
          that.$refs.dialogNode.openDialog({ lnglat: that.lnglat, addOrEdit: 'add' })
          that.$parent.drawTipmouse = ''
        })
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 开始绘制
     * 开发人： 宋德福
     * 开发时间： 2022-5-20
     * @type 节点node、管线line、阀门valve、用户站userSta、供气站station、拖拽、打断等
     * @activeToolItem 当前选中工具
     ****************************************************************************************************
     */
    startDraw(type, layer, name, layerName) {
      let sketchType = this.$store.getters.legendSelect
      // this.$parent.queryLayer=layerName
      if (type == 'interrupt') {
        this.$parent.drawTipmouse = '选择管线任意位置或节点，单击完成打断'
      } else {
        this.$parent.drawTipmouse = '单击节点移动位置，完成拖拽'
      }

      this.$parent.drawType = ''
      this.$parent.isEdit = this.activeToolItem == type ? false : true
      this.activeToolItem = this.activeToolItem == type ? '' : type

      this.activeOptionItem = 'adsorption'
      this.lineArr = []
      this.selectPipe = []
      this.toolActive = ''
      this.lineType = 'start'
      this.openLoad = false
      this.closeTool()
      this.$eventBus.$emit('clearDrag')
      if (layer != 'no') {
        this.$parent.queryLayer = this.activeToolItem == type ? layerName : ''
        this.$parent.queryType = this.activeToolItem == type ? type : ''
      } else {
        this.$parent.queryLayer = ''
        this.$parent.queryType = ''
      }
      if (['node', 'monitoring', 'valve', 'interrupt'].indexOf(this.activeToolItem) == -1) {
        // 管线、供气站、拖拽 可以吸附可以不吸附
        this.$refs.adsorption.style.cursor = 'pointer'
      } else {
        // 节点、监测点、阀门、用户站、打断的时候，打开吸附，吸附按钮禁止点击
        this.$refs.adsorption.style.cursor = 'not-allowed'
      }

      if (this.$parent.isEdit) {
        this.drawLineAndNode()
        if (layer && sketchType.indexOf(layer) == -1 && type != 'drag' && type != 'interrupt') {
          this.$notify({
            title: '提示',
            message: `您当前未打开"${name}"图例，会影响绘制结果可见性！`,
            position: 'bottom-right',
            type: 'warning',
            customClass: 'notify-warning'
          })
        }
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 切换吸附按钮
     * 开发人： 宋德福
     * 开发时间： 2022-5-26
     ****************************************************************************************************
     */
    startOption(item) {
      let type = item.value
      if (this.activeToolItem == 'warn' || this.activeToolItem == 'camera') {
        this.activeOptionItem = ''
      } else if (['node', 'monitoring', 'valve', 'interrupt'].indexOf(this.activeToolItem) == -1) {
        // 吸附、用户站、供气站，可以关闭吸附
        let a = this.activeOptionItem == type ? '' : type
        this.activeOptionItem = a
        this.$parent.setDrawPoint(a)
      } else {
        // 节点、监测点、阀门的时候，打开吸附，吸附按钮禁止点击
        this.activeOptionItem = 'adsorption'
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 绘制节点、管线等
     * 开发人： 宋德福
     * 开发时间： 2022-5-20
     ****************************************************************************************************
     */
    drawLineAndNode() {
      let that = this
      switch (that.activeToolItem) {
        case 'node': {
          that.$parent.editTool.marker({
            icon: new AMap.Icon({
              image: require('../../image/tool-node.png'),
              imageOffset: new AMap.Pixel(0, 0)
            }),
            anchor: 'center',
            offset: new AMap.Pixel(0, 0)
          })
          that.$parent.drawTipmouse = '单击，完成绘制'
          break
        }
        case 'monitoring': {
          that.$parent.editTool.marker({
            icon: new AMap.Icon({
              image: require('../../image/tool-monitoring.png'),
              imageOffset: new AMap.Pixel(0, 0)
            }),
            anchor: 'center',
            offset: new AMap.Pixel(0, 0)
          })
          that.$parent.drawTipmouse = '单击，完成绘制'
          break
        }
        case 'valve': {
          that.$parent.editTool.marker({
            icon: new AMap.Icon({
              image: require('../../image/tool-valve.png'),
              imageOffset: new AMap.Pixel(0, 0)
            }),
            anchor: 'center',
            offset: new AMap.Pixel(0, 0)
          })
          that.$parent.drawTipmouse = '单击，完成绘制'
          break
        }
        case 'userSta': {
          that.$parent.editTool.marker({
            icon: new AMap.Icon({
              image: require('../../image/tool-userSta.png'),
              imageOffset: new AMap.Pixel(0, 0)
            }),
            anchor: 'center',
            offset: new AMap.Pixel(0, 0)
          })
          that.$parent.drawTipmouse = '单击，完成绘制'
          break
        }
        case 'station': {
          that.$parent.editTool.marker({
            icon: new AMap.Icon({
              image: require('../../image/tool-station.png'),
              imageOffset: new AMap.Pixel(0, 0)
            }),
            anchor: 'center',
            offset: new AMap.Pixel(0, 0)
          })
          that.$parent.drawTipmouse = '单击，完成绘制'
          break
        }
        case 'line': {
          that.lineArr = []
          that.lineType = 'start'
          that.openLoad = false
          that.$parent.editTool.polyline({
            strokeColor: '#80d8ff'
          })
          that.$parent.drawState = true
          that.$parent.tipMessege = '移动鼠标，双击结束绘制'
          that.$parent.showTip = true
          that.$parent.tipPoint = []
          that.$parent.drawTipmouse = '单击确定起点，双击结束绘制'
          break
        }
      }
    },
    endToolDraw() {
      let that = this

      that.$parent.editTool.on('draw', function(event) {
        let obj = event.obj
        if (obj.className == 'AMap.Marker') {
          that.lnglat = obj.getPosition().lng + ' ' + obj.getPosition().lat
          that.getClosestFeature(event.obj)
        } else if (obj.className == 'Overlay.Polyline') {
          if (!that.lineArr) {
            that.getClosestFeature(event.obj)
          } else {
            // console.log(event.obj.getPath())
            that.lineOpen()
            if (that.infoWindow) {
              that.infoWindow.close()
            }
          }
        }
      })
    },
    lineOpen() {
      let that = this
      let pipeLengthTotal = null
      //  that.lineType = 'start'
      that.openLoad = true
      let lineArr = that.lineArr.filter(el => el.endPoint && el.startPoint)
      lineArr.forEach(el => {
        that.$set(el, 'line', el.startPoint + ', ' + el.endPoint)
        let pipeLength = AMap.GeometryUtil.distanceOfLine([
          el.startPoint.split(' '),
          el.endPoint.split(' ')
        ])
        pipeLengthTotal += pipeLength
        that.$set(el, 'pipeLength', pipeLength.toFixed(2))
      })

      if (lineArr.length) {
        that.$refs.dialogNode.openDialog({ lineArr, pipeLength: pipeLengthTotal.toFixed(2) })
        that.$parent.drawTipmouse = ''
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 获取指定范围内距离最近的点
     * 开发人： 宋德福
     * 开发时间： 2022-5-20
     * @obj mouseTool绘制的覆盖物对象
     ****************************************************************************************************
     */
    setlineArr(obj) {},
    getClosestFeature(obj) {
      let that = this

      let lnglat = []
      if (that.activeToolItem == 'node' || that.activeToolItem == 'monitoring') {
        let msg = (that.activeToolItem == 'node' ? '节点' : '监测点') + '只能落到管线上'
        lnglat = obj.getPosition()

        let features = that.$parent.queryFeatrue
        if (features) {
          let pathStr = features.properties.location_str
          let path = formatLocation(pathStr, 'LineString')
          let closestPositionOnLine = features.pointObj
          obj.setPosition(closestPositionOnLine)
          let pipelineLnglat = path
          that.$refs.dialogNode.openDialog({
            id: features.properties.id,
            lnglat: closestPositionOnLine.join(' '),
            pipelineLnglat
          })
          that.$parent.drawTipmouse = ''
        } else {
          that.$message.warning(msg)
          obj.remove()
        }
        // })
      } else if (that.activeToolItem == 'valve') {
        let msg = '阀门只能落到点或者管线上'
        lnglat = obj.getPosition()
        //   that.getRangeFeature(lnglat, commonLayer['groupLayer']).then(function(res) {
        let features = that.$parent.queryFeatrue
        if (features) {
          if (features.geometry.type == 'Point') {
            // 点在了节点上
            if (features.properties.channel_type) {
              msg = '该管线为场站进出站管线，不支持绑定场站、设备、管件等'
              that.$message.warning(msg)
              obj.remove()
            } else {
              let pointStr = features.properties.location_str
              let point = formatLocation(pointStr, 'Point')
              obj.setPosition(point)
              that.$refs.dialogNode.openDialog({
                id: features.properties.id,
                point: pointStr.split('(')[1].split(')')[0],
                type: 'node'
              })
              that.$parent.drawTipmouse = ''
            }
          } else if (features.geometry.type == 'LineString') {
            let pathStr = features.properties.location_str
            let path = formatLocation(pathStr, 'LineString')

            let closestPositionOnLine = features.pointObj
            obj.setPosition(closestPositionOnLine)

            let pipelineLnglat = path
            that.$refs.dialogNode.openDialog({
              id: features.properties.id,
              point: closestPositionOnLine.join(' '),
              pressure_level: features.properties.pressure_level,
              pipelineLnglat,
              type: 'line'
            })

            that.$parent.drawTipmouse = ''
          }
        } else {
          // 没有数据
          that.$message.warning(msg)
          obj.remove()
        }
        // })
      } else if (that.activeToolItem == 'line') {
        let lineobj = {
          endPoint: null,
          endPointId: null,
          startPoint: null,
          startPointId: null
        }
        if (that.lineType === 'start') {
          that.lineArr.push(lineobj)
        }
        // 管线可以吸附可以不吸附
        lnglat = obj.getPath()[obj.getPath().length - 2]
        that.mouseIndex = that.lineArr.length

        if (that.mousePre) {
          //上一个是三通
          let lnglat2 = obj.getPath()[obj.getPath().length - 3]
          let html = that.$refs.changePoint
          that.infoWindow = new AMap.InfoWindow({
            position: lnglat2,
            offset: new AMap.Pixel(0, 0),
            anchor: 'top-left',
            content: html
          })
          that.infoWindow.open(that.$parent.map)
        }
        that.mouseObj = obj
        that.mousePre = false

        let coordinates = lnglat.lng + ' ' + lnglat.lat
        if (that.lineType === 'start') {
          that.lineArr[that.lineArr.length - 1].startPoint = coordinates
        } else {
          that.lineArr[that.lineArr.length - 1].endPoint = coordinates
          setTimeout(() => {
            that.lineArr.push(lineobj)
            that.lineArr[that.lineArr.length - 1].startPoint = coordinates
          }, 300)
        }

        if (that.$store.getters.activeOptionItem === 'adsorption') {
          // that
          // .getRangeFeature(lnglat, commonLayer['basePoint'])
          // .then(function(res) {
          let data = [that.$parent.queryFeatrue] //res.features
          let features = []
          if (that.$parent.queryFeatrue && that.$parent.queryFeatrue.properties) {
            data.forEach(lineItem => {
              //进站 出站 供气 用户
              if (
                lineItem.properties.node_type2 != constantDict.gongqizhan.dictCode &&
                lineItem.properties.node_type2 != constantDict.yonghuzhan.dictCode
              ) {
                features.push(lineItem)
              }
            })
          }
          if (features.length > 0) {
            if (
              features[0].properties.channel_type == 1 ||
              features[0].properties.channel_type == 2
            ) {
              that.$message.warning('该管线为场站进出站管线，不支持绘制管线')
              return
            } else {
              pipeLink({ pointIds: features[0].properties.id })
                .then(res => {
                  if (that.windowState) {
                    that.windowState = false
                    return
                  }
                  that.windowState = false
                  let repeat = false
                  if (res.data.length > 0) {
                    res.data[0].pipes.forEach(item => {
                      that.selectPipe.forEach(old => {
                        if (item.id == old.id) {
                          repeat = true
                        }
                      })
                    })

                    if (repeat) {
                      return
                    }
                    that.selectPipe = res.data[0].pipes || []

                    let l = 0
                    if (res.data[0].pipes) {
                      l = res.data[0].pipes.length
                    }
                    console.log(l)
                    if (l > 2) {
                      that.$message.warning('不支持同一点连接3条以上管线')
                      that.activeOptionItem = ''
                    } else {
                      let point = features[0].properties.location_str

                      if (
                        (point && l < 3 && that.lineType === 'start') ||
                        (point && l < 2 && that.lineType !== 'start')
                      ) {
                        let location = formatLocation(point, 'Point')
                        let path = obj.getPath()
                        let i = path.length - 2
                        path[i] = location
                        obj.setPath(path)
                        lnglat = new AMap.LngLat(location[0], location[1])
                      }

                      let adsorptionP = lnglat.lng + ' ' + lnglat.lat
                      if (that.lineType === 'start') {
                        that.lineArr[that.lineArr.length - 1].startPointId =
                          features[0].properties.id
                        that.lineArr[that.lineArr.length - 1].startPoint = adsorptionP
                      } else {
                        setTimeout(() => {
                          console.log(l)
                          console.log(that.lineType)
                          console.log(that.openLoad)

                          that.lineArr[that.lineArr.length - 2].endPoint = adsorptionP
                          that.lineArr[that.lineArr.length - 1].startPoint = adsorptionP
                          if (
                            (point && l < 3 && that.lineType === 'start' && !that.openLoad) ||
                            (point && l < 2 && that.lineType !== 'start' && !that.openLoad)
                          ) {
                            that.lineArr[that.lineArr.length - 2].endPointId =
                              features[0].properties.id
                            that.lineArr[that.lineArr.length - 1].startPointId =
                              features[0].properties.id
                          } else if (that.openLoad && l == 2 && point) {
                            //结束时
                            that.lineArr[that.lineArr.length - 2].endPointId =
                              features[0].properties.id
                            that.lineArr[that.lineArr.length - 1].startPointId =
                              features[0].properties.id
                            let location = formatLocation(point, 'Point')
                            let path = obj.getPath()
                            let i = path.length - 1
                            path[i] = location
                            obj.setPath(path)
                            lnglat = new AMap.LngLat(location[0], location[1])
                            adsorptionP = lnglat.lng + ' ' + lnglat.lat
                            console.log(adsorptionP)
                            that.lineArr[that.lineArr.length - 2].endPoint = adsorptionP
                            that.lineArr[that.lineArr.length - 1].startPoint = adsorptionP
                          }
                          if (that.openLoad) {
                            that.lineOpen()
                          }
                        }, 500)
                      }
                    }

                    if (l === 1 && that.lineType !== 'start') {
                      that.mousePre = true
                    }

                    if (l === 2) {
                      //  that.$message.warning('该点将会自动转化为三通')

                      // that.mouseObj=obj
                      //  that.mouseIndex=that.lineArr.length-2
                      //   that.mouseObj=obj
                      //   that.mouseIndex=that.lineArr.length - 1

                      if (that.lineType !== 'start') {
                        // that.$parent.editTool.close()
                        // that.lineOpen()
                      } else {
                        let html = that.$refs.changePoint
                        that.infoWindow = new AMap.InfoWindow({
                          position: lnglat,
                          offset: new AMap.Pixel(0, 0),
                          anchor: 'top-left',
                          content: html
                        })
                        that.infoWindow.open(that.$parent.map)
                      }
                    }
                  }
                  if (that.lineType === 'start') {
                    that.lineType = 'end'
                  }
                })
                .catch(err => {
                  if (that.lineType === 'start') {
                    that.lineType = 'end'
                  }
                  that.$message.error(err)
                })
            }
          } else {
            if (that.lineType === 'start') {
              that.lineType = 'end'
            }
          }
          // })
          // .catch(err => {
          //   if (that.lineType === 'start') {
          //     that.lineType = 'end'
          //   }
          // })
        } else {
          that.selectPipe = []
          if (that.lineType === 'start') {
            that.lineType = 'end'
          }
        }
      } else if (that.activeToolItem == 'userSta') {
        lnglat = obj.getPosition()
        if (that.$store.getters.activeOptionItem != 'adsorption') {
          that.$parent.drawTipmouse = ''
          let param = {
            geometryType: null,
            equipmentType: 'userSta',
            title: '用户站',
            point: lnglat.lng + ' ' + lnglat.lat
          }
          that.$parent.$refs.stationInfo.openDialog(param)
          /* that.$refs.dialogNode.openDialog({
            equipmentType: 'userSta',
            point: lnglat.lng + ' ' + lnglat.lat
          }) */
        } else {
          let msg = '用户站只能吸附到次高压、中压的自由端普通节点上'

          let features = that.$parent.queryFeatrue
          if (features && features.properties && features.properties.id) {
            if (features.properties.channel_type == 1 || features.properties.channel_type == 2) {
              that.$message.warning('该管线为场站进出站管线，不支持绑定场站、设备、管件等')
              obj.remove()
            } else {
              findIsPressureFree({ id: features.properties.id })
                .then(res => {
                  if (res.code == 200) {
                    if (res.data) {
                      let param = {
                        geometryType: null,
                        equipmentType: 'userSta',
                        title: '用户站',
                        ...res.data
                      }
                      let pointStr = features.properties.location_str
                      let point = formatLocation(pointStr, 'Point')
                      obj.setPosition(point)

                      that.$parent.drawTipmouse = ''

                      param.point = pointStr.split('(')[1].split(')')[0]
                      param.pointId = features.properties.id
                      that.$parent.$refs.stationInfo.openDialog(param)
                    } else {
                      that.$message.error(msg)
                      obj.remove()
                    }
                  } else {
                    that.$message.error(res.msg)
                    obj.remove()
                  }
                })
                .catch(err => {
                  that.$message.error(err)
                  obj.remove()
                })
            }
          } else {
            that.$message.warning(msg)
            obj.remove()
          }
        }
      } else if (that.activeToolItem == 'station') {
        lnglat = obj.getPosition()
        if (that.$store.getters.activeOptionItem != 'adsorption') {
          that.$parent.drawTipmouse = ''
          let param = {
            geometryType: null,
            equipmentType: 'station',
            title: '供气站',
            point: lnglat.lng + ' ' + lnglat.lat
          }
          that.$parent.$refs.stationInfo.openDialog(param)
          /* that.$refs.dialogNode.openDialog({
            equipmentType: 'station',
            point: lnglat.lng + ' ' + lnglat.lat
          }) */
          that.$parent.drawTipmouse = ''
        } else {
          let msg = '供气站只能吸附到普通节点上'
          let features = that.$parent.queryFeatrue

          if (features) {
            if (features.properties.channel_type == 1 || features.properties.channel_type == 2) {
              that.$message.warning('该管线为场站进出站管线，不支持绑定场站、设备、管件等')
              obj.remove()
            } else {
              let pointStr = features.properties.location_str
              let point = formatLocation(pointStr, 'Point')
              obj.setPosition(point)

              that.$parent.drawTipmouse = ''

              let param = {
                geometryType: null,
                equipmentType: 'station',
                title: '供气站'
              }
              param.point = pointStr.split('(')[1].split(')')[0]
              param.pointId = features.properties.id
              that.$parent.$refs.stationInfo.openDialog(param)
            }
          } else {
            that.$message.warning(msg)
            obj.remove()
          }
          // })
        }
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 获取地图上100范围内所有数据
     * 开发人： 宋德福
     * 开发时间： 2022-5-26
     * @lnglat 经纬度
     * @layerName 图层、图层组名称
     * @filter 查询条件
     ****************************************************************************************************
     */
    async getRangeFeature(lnglat, layerName) {
      let slq = filterLayer(this.$store)
      let filter = {}
      if (slq) {
        filter = { CQL_FILTER: slq }
      }
      let points = centerPointGetFourPoint(this.$parent.map, lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let STYLES = ''
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: layerName,
        LAYERS: layerName,
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        STYLES,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        ...filter
      }
      return await queryFeatrue(parames)
    }
  }
}
</script>

<style lang="scss" scoped>
.back-style {
  min-width: 20px !important;

}
.selectColor {
  background: var(--background-hover-skin);
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  padding: 0 2px 0 8px;
}
.sketchImg {
  display: flex;
  .span {
    width: 30px;
    height: 30px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .span:hover {
    background: var(--background-hover-skin);
  }
  .span.active {
    background: var(--background-hover-skin);
  }
  .img {
    height: 25px;
    width: auto;
    cursor: pointer;
    text-align: center;
  }
}
.tool-bar {
  // float: left;
  display: flex;
  font-size: 14px;
  height: 50px;
  //margin-left: 4px;
  color: var(--font-color-top);
  background: var(--background-color-skin);
  border: 1px solid var(--border-color2-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .tool-name {
    float: left;
    height: 36px;
    margin: 7px 0;
    padding: 0 10px;
    line-height: 36px;
    box-shadow: 1px 0px 0px 0px var(--boxshadow-color-skin);
  }
  .tool-list {
    overflow: hidden;
  }
  .isShow {
    display: flex;
    align-items: center;
    &.unfold {
      transform: rotate(180deg);
    }
    img {
      width: 10px;
    }
  }
  li {
    float: left;
    height: 36px;
    margin: 7px 0;
    text-align: center;
    box-shadow: 1px 0px 0px 0px var(--boxshadow-color-skin);
    div {
      height: 42px;
      line-height: 16px;
      min-width: 46px;
      margin: -5px 2px 2px 3px;
      padding: 6px 4px;
      color: var(--font-color-top);
      img {
        display: block;
        margin: 0 auto;
      }
      .img {
        height: 18px;
        width: auto;
      }
      .icon {
        height: 16px;
        width: 16px;
      }
      span {
        display: block;
        font-size: 12px;
      }
    }
    .active {
      color: var(--font-color-topA) !important;
      background: var(--background-active-skin) !important;
    }
    div:hover {
      background: var(--background-hover-skin);
      cursor: pointer;
    }
  }
}
.tool-pop-item {
  padding: 4px 0;
  li {
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    img,
    .icon {
      height: 16px;
      width: 40px;
    }
  }
  li:hover {
    background: var(--background-hover-skin);
  }
  .active {
    color: var(--font-color-f1) !important;
    background: var(--background-active-skin) !important;
  }
}

.bubbleBox {
  position: absolute;
  z-index: -1;
  display: none;
}
.Bubble {
  div {
    font-size: 14px;
  }
  position: relative;
  .lj {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: -21px;
    transform: rotate(-90deg);
    z-index: 1;
    .tip {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #222;
      position: relative;
    }
    .tip:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      left: -12px;
      z-index: -1;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid rgba(0, 204, 255, 0.5);
    }
    // border-bottom: 12px solid rgba(8, 31, 48, 1);
  }
}
.markbox {
  background: #15202f;
  border: 1px solid #6eb7e3;

  min-width: 290px;
  min-height: 115px;

  // background-size: 100% 100%;
  font-size: 18px;
  // margin-top: -0.6rem;
  margin-left: 0.1rem;
  overflow: visible;
  position: relative;
}
.markTitle {
  height: 42px;
  display: flex;
  // line-height: 1;
  justify-content: space-between;
  align-items: center;
  //background-image: linear-gradient(to right, #0a608c, #081f30);
  background-color: var(--background-title-skin);
  opacity: 1;
  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    img {
      margin-right: 10px;
      height: 24px;
      width: auto;
    }
  }
  i {
    font-size: 18px;
    color: #679db4;
    cursor: pointer;
    margin-right: 10px;
  }
  i:hover {
    color: #548aa1;
  }
}
.mark-con {
  margin: 5px 10px;
  line-height: 2;
  .li:hover,
  .li.active {
    color: var(--font-color-b1);
    cursor: pointer;
  }
}
.btn {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
.tool-img{background-color: #899FBB;width: 10px;height: 51px;line-height:51px;text-align: center;}
.tool-img img{padding-top:15px}
</style>
